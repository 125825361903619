@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
	overflow-x: hidden;
}

body {
	font-family: "DM Sans", sans-serif;
	font-optical-sizing: auto;
	font-weight: 400;
	font-style: normal;
	overflow-x: hidden;
	/* background: #eef0f3; */
}
img {
	max-width: 100%;
}
.tradingview-widget-copyright {
	display: none;
}

/* Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
	display: none;
}

.no-scrollbar {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}
